import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { getConfigMainMenu } from 'projects/evolutics-client-ui/src/app/configs/menu-configs/top-menu.config';
import { AdminAPIService } from 'projects/evolutics-admin-ui/src/app/Services/admin-api.service';
import { ISearchResult } from 'projects/evolutics-admin-ui/src/app/Tasks/task-extras/task-setup.interface';
import { MenuItem } from '@Shared/models/IMenuItem';
import { DocNavComponent } from '../../../shared/components/doc-nav/doc-nav.component';

@Component({
    selector: 'app-client=api-docs',
    templateUrl: './client-view-api.component.html',
    styleUrls: ['./client-view-api.component.scss'],
    standalone: true,
    imports: [DocNavComponent],
})
export class ClientViewApiComponent implements OnInit {
  loading: boolean;
  allMenus: MenuItem[] = [];
  constructor(
    private apiS: AdminAPIService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAllMenus();
    this.loading = false;
  }

  /**
   *
   * @param query Search query
   * @returns Search observable for FindItemComponent
   */
  search = (obj: any): Observable<ISearchResult> => {
    return this.apiS.searchApiDoc(obj);
  };
  getAllMenus() {
    this.allMenus = getConfigMainMenu();
    console.log(this.allMenus);
  }
}
