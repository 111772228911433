import { MediaMatcher } from '@angular/cdk/layout';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { environment } from '@environments/environment';
import { AppService } from 'ets-fe-ng-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { UtilityService } from '@Services/utility.service';
import { MenuItem } from '@Shared/models/IMenuItem';
import PS from 'perfect-scrollbar';
import { Observable } from 'rxjs';
import { TranslatePipe } from '../../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { ModalComponent } from '../../../../../../evolutics-shared-lib/src/lib/Shared/components/modal/modal.component';
import { ViewTableComponent } from '../view-table/view-table.component';
import { QuillViewComponent } from 'ngx-quill';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { InputFormatDirective } from '../../../../../../evolutics-shared-lib/src/lib/Shared/directives/input-formatter.directive';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe, JsonPipe } from '@angular/common';

@Component({
    selector: 'app-doc-nav',
    templateUrl: './doc-nav.component.html',
    styleUrls: ['./doc-nav.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        MatToolbarModule,
        InputFormatDirective,
        NgFor,
        MatSidenavModule,
        LoaderComponent,
        NgSwitch,
        NgSwitchCase,
        QuillViewComponent,
        ViewTableComponent,
        NgSwitchDefault,
        ModalComponent,
        AsyncPipe,
        JsonPipe,
        TranslatePipe,
    ],
})
export class DocNavComponent {
  @Input() fullScreen: boolean;
  @Input() isHome: boolean;
  @Input() showMenu: boolean;
  @Input() showSearch: boolean;
  @Input() title: string;
  @Input() getter: (code: any) => Observable<any>;
  @Input() set menuItems(v: MenuItem[]) {
    if (!v?.length) return;
    v.forEach((x) => (x.level = 0));
    this.dataSource = new MatTreeNestedDataSource<MenuItem>();
    this.dataSource.data = v;
  }

  id = 'matSideNav' + this.uS.genRandomID;
  apiContent: any[] = null;
  searchContent: any[] = null; 
  user = environment.userProfile;
  treeControl = new NestedTreeControl<MenuItem>((node) => node.subs);

  dataSource: MatTreeNestedDataSource<MenuItem>;

  @ViewChild('tnav') drawer: MatDrawer;
  mobileQuery: MediaQueryList;
  loading: boolean = false;
  slug: string;
  private _mobileQueryListener: () => void;

  /* This file will be reviewed to determine what is actually needed and what isn't */

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appS: AppService,
    public uS: UtilityService,
    public router: Router,
    private route: ActivatedRoute,
    public authS: AuthenticationService, 
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap;
    this.slug = queryParams.get('slug');
    this.route.queryParamMap.subscribe({
      next: (r) => {
        this.getter({screenPath: r.get('slug')}).subscribe({
          next: (v) => {
            this.apiContent = v.content;
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          },
        });
      }
    })
  }

  onClick(node: any) {
    this.loading = true;
    this.router.navigate([], {relativeTo: this.route, queryParams: {slug: node.id}});
    return;
    this.getter(this.slug).subscribe({
      next: (v) => {
        this.apiContent = v.content;
        console.log('slug', this.slug);
        console.log('api content', this.apiContent);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

/**
 * Routes to relative path of selected description in search input
 * @param node 
 * @returns 
 */
  onClickSearch(node: any) {
    this.loading = true;
    this.router.navigate([], {relativeTo: this.route, queryParams: {slug: node.code}});
    return;
  }

  /**
   * Searches all API documentation via description
   * @param searchString 
   */
  search(searchString: any){
    if (searchString.target.value == "") {
      this.searchContent = [];
    } else {
      this.getter({description: searchString.target.value}).subscribe({
        next: (v) => {
          this.searchContent = v.content;
          console.log('api content', this.searchContent);
        },
        complete: () => {
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit(): void {
    this.drawer.open();
    const doc = document.querySelector<HTMLDivElement>(
      `#${this.id} .mat-drawer-inner-container`
    );
    if (doc) {
      doc.style.position = 'relative';
      const ps = new PS(doc, {
        wheelSpeed: 0.5,
        swipeEasing: false,
        wheelPropagation: true,
        minScrollbarLength: 40,
      });
    }
  }
 
  signOut() {
    this.uS.startLoader();
    this.authS.logout().then(() => this.router.navigateByUrl('/'));
  }
}
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}
