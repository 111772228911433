<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" [ngClass]="{fullScreen}">
  @if (fullScreen) {
<mat-toolbar color="primary" class="example-toolbar bg-primary d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h6 class="example-app-name">{{title|appTranslate|async}}</h6>
    </div>
    <div class="col-10 col-sm-4 col-md-3 search-container">
      <input type="text" id="search" icon="search" (keyup)="search($event)" class="form-control" placeholder="Search Description">
      <div class="option-container">
        @for (item of searchContent; track item) {
  <div class="" (click)="onClickSearch(item)">
          {{item.description}}
        </div>
}
      </div>
    </div>
  </mat-toolbar>
}
  <mat-sidenav-container [id]="id" class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <!-- <mat-sidenav class="side-w" #tnav [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" [hidden]="!showMenu">
      <div class="p-3">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"> -->
          <!-- This is the tree node template for leaf nodes -->
          <!-- This is the tree node template for expandable nodes -->
          <!-- <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="expandable-node">
            <div class="mat-tree-node">
              <button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="menu-btn level{{node.level}}">
                <div class="row">
                  <div class="col">
                    {{node.label|appTranslate|async}}
                  </div>
                  <div class="col-auto">
                    <span class="fa {{treeControl.isExpanded(node) ? 'fa-chevron-down':'fa-chevron-right'}} ">
                    </span>
                  </div>
                </div>
              </button>
            </div>
            <div> -->
              <!-- There is inline padding applied to this div using styles.
              This padding value depends on the mat-icon-button width.  -->
              <!-- <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group" class="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </div>
          </mat-nested-tree-node> -->
          <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
          <!-- <mat-tree-node *matTreeNodeDef="let node;when shouldShow" matTreeNodeToggle
            class="single-node level{{node.level}}">
            <span class="d-block w-100 pointer" (click)="onClick(node)">
              {{node.label|appTranslate|async}}
            </span> -->
            <!-- <a [mrouterLink]="node.link">
                  <span class="{{node.icon}}"></span> {{node.label|appTranslate|async}}
                </a> -->
          <!-- </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle
            class="single-node restricted level{{node.level}}"> -->
            <!-- <a [mrouterLink]="node.link">
                  <span class="{{node.icon}}"></span> {{node.label|appTranslate|async}}
                </a> -->
          <!-- </mat-tree-node>
        </mat-tree>

      </div>
    </mat-sidenav> -->

    <mat-sidenav-content class="">
      <div class="row justify-content-end">
        <!-- <div class="col-10 col-sm-4 col-md-3">
          <app-btn type="secondary" text="Search" icon="search" (mclick)="[lModal.open()]"/>
        </div> -->
      </div>
      <loader [loading]="loading">
        @if (apiContent?.length) {

          <div class="breadcrumb">
            <h3><span>{{apiContent[0].module}}/{{apiContent[0].menu}}/{{apiContent[0].screenPath}}</span></h3>
          </div>
          @for (content of apiContent; track content) {
  
            <div class="py-5">
              <h4>{{content.description}}</h4>
              @for (message of content.texts; track message) {
  
                
@switch (message.contentType) {
                  @case ('TEXT') {
  
                    <quill-view [content]="message.content?.content"></quill-view>
                  
}
                  @case ('TABLE') {
  
                    <app-view-table [configExact]="message.content?.config" [data]="message.content?.content">
                    </app-view-table>
                  
}
                  @default {
  
                    {{message.content|json}}
                  
}
                }

              
}
            </div>
          
}
        
} @else {

          No {{ apiContent ? 'documentation' : 'module'}} has been {{ apiContent ? 'provided' : 'selected'}} yet.
        
}
              </loader>
    </mat-sidenav-content>

    <modal-comp #lModal header="Search Description">
      <div class="" body>
        <div class="form-group">
          <div class="col-md-20">
            <input type="text" id="search" (keyup)="search($event)" class="form-control" placeholder="Search Description">
          </div>
        </div>
      </div>
    </modal-comp>
  </mat-sidenav-container>
</div>
